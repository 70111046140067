import React, { useState } from "react";
import ContactBanner from "../img/banner/realestate-banner.jpg";
import "../Css/Contact.css";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";
import $ from "jquery";

const Contact = () => {
  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
    custSubject: "",
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custSubject: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
    if (!custSubject.trim()) {
      isValid = false;
      newErrors.custSubject = "Enter Subject People";
    }

    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  // ============ Mail function ==========
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Dreammland Resort</h2><p>Hello Dreammland Resort,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custContact +
        '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custSubject +
        '</td></tr><tr><td style="text-align:left"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Shree Krishna Digital Marketing</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "mcshah.dreammland@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName:"dreammland",
          accountLeadSource: "",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
    {/* ========= Seo start========== */}
    <MetaTags>
        <title>Top Resort in Thane | Dreamland Resort</title>
        <meta
          name="description"
          content="Discover the epitome of luxury at the Top Resort in Thane. Indulge in opulence, and breathtaking views with extraordinary retreat begins here!!"
        />
        <meta
          property="title"
          content="Top Resort in Thane | Dreamland Resort"
        />
        <meta
          property="og:title"
          content="Top Resort in Thane | Dreamland Resort"
        />
       <meta
          name="og:description"
          content="Discover the epitome of luxury at the Top Resort in Thane. Indulge in opulence, and breathtaking views with extraordinary retreat begins here!! "
        />
        <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/top-resort-thane" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <section className="contact-section">
        <div className="contact-bottom-pattern" />
        <div className="contactBanner">
          <div className="container">
            <div className="contactbannerTxt">
            <h1 style={{color:"#fff", fontSize:'10px'}}>Top Resort in Thane</h1>
  
              <h2>Contact At Dreammland</h2>
             
            </div>
          </div>
        </div>
      </section>
  
      {/* ======== Contact Section ====== */}
      <section className="contact-section-two">
        <div className="container clearfix">
          <div className="row clearfix">
            <div className="info-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="u-text">
                <h4 style={{fontSize:"29px"}}>Get In Touch</h4>
                </div>
                <div className="info">
                 
                  <div className="info-block">
                    <div className="block-inner">
                    <div
                        className="icon-box">
                        <span><i className="fa fa-map-marker"></i></span>
                      </div>
                      <h4>Address</h4>
                      <div className="text">
                        <a href="">Dreammland Resort, Near Kundan Lake, Kundan Pada, Shirol, Maharashtra - 421602</a>
                      </div>
                    </div>
                  </div>
                  <div className="info-block">
                    <div className="block-inner">
                      <div
                        className="icon-box">
                        <span><i className="fa fa-phone"></i></span>
                      </div>
                      <h4>Contact Detail</h4>
                      <div className="contact_num">
                     
                      <div className="text ">
                      <h6>Reservation & Enquiries</h6>
                        <a href="tel:+91 8425091256">  +91-84250 91256



</a>{" "}
                       
                      <h6>For Events:</h6>
                        <a href="tel:+919324545580">  +91-93245-45580</a>{" "}
                        <br/>
                        <h6>Lobby Manager:</h6>
                        <a href="tel: +918691082257"> +91 86910 82257</a>
                      
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="form-box default-form contact-form-two ">
                  <h2>Have Any Question?</h2>
                  <div className="text">
                    Please complete the details below and then click on Submit
                    and we’ll be in contact
                  </div>
                  <form>
                    <div className="form-group">
                      <div className="field-inner">
                        <input
                          type="text"
                          name="Name"
                          placeholder="Name"
                          value={custName}
                        onChange={(e) => setCustName(e.target.value)}
                      />
                   
                      </div>
                    </div>
                    {errors.custName && (
                        <span className="error-text">{errors.custName}</span>
                      )}
                    <div className="form-group">
                      <div className="field-inner">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={custEmail}
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                     
                      </div>
                    </div>
                    {errors.custEmail && (
                        <span className="error-text">{errors.custEmail}</span>
                      )}
                    <div className="form-group">
                      <div className="field-inner">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone No"
                          value={custContact}
                          onChange={(e) => setCustContact(e.target.value)}
                        />
                       
                      </div>
                    </div>
                    {errors.custContact && (
                          <span className="error-text">{errors.custContact}</span>
                        )}
                    <div className="form-group">
                      <div className="field-inner">
                        <input
                          type="text"
                          name="lastname"
                          placeholder="Subject"
                          value={custSubject}
                          onChange={(e) => setCustSubject(e.target.value)}
                        />
                      
                      </div>
                    
                    </div>
                    {errors.custContact && (
                          <span className="error-text">{errors.custSubject}</span>
                        )}
                    <div className="form-group">
                      <div className="field-inner">
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={custMessage}
                          onChange={(e) => setCustMessage(e.target.value)}
                        ></textarea>
                       
                      </div>
                    </div>
                    {errors.custMessage && (
                          <span className="error-text">{errors.custMessage}</span>
                        )}
                    <div className="form-btn">
                      <button className="theme-btn btn-style-one" onClick={ServForm}>
                        <span className="btn-title">Send Message</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======== Contact Map ======== */}
      <section className="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7514.721167061767!2d73.412677!3d19.654616!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be77df88d08eecd%3A0xdb412df89f8312e9!2sDreammland%20Resort!5e0!3m2!1sen!2sin!4v1698410420303!5m2!1sen!2sin"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
