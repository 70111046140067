// =========== Insta Img ======
import Gallery1 from "../img/wedding/wed1.jpg";
import Gallery2 from "../img/wedding/wed2.jpg";
import Gallery3 from "../img/wedding/wed3.jpg";
import Gallery4 from "../img/wedding/wed4.jpg";
import Gallery5 from "../img/wedding/wed5.jpg";
import Gallery6 from "../img/wedding/wed6.jpg";
import Gallery7 from "../img/wedding/wed7.jpg";
import Gallery8 from "../img/wedding/wed8.jpg";
import Gallery9 from "../img/wedding/wed9.jpg";

const photos = [
    {
        src:Gallery1,
        width:3,
        height:3,
      },
      {
        src:Gallery2,
        width:3,
        height:2,
      },
      {
        src:Gallery3,
        width:3,
        height:3,
      },
      {
        src:Gallery4,
        width: 3,
        height: 2,
      },
      {
        src:Gallery5,
        width:3,
        height:2,
      },
      {
        src:Gallery6,
        width:3,
        height:2,
      },
      {
        src:Gallery8,
        width:3,
        height:3,
      },
      {
        src:Gallery7,
        width:3,
        height:2,
      },
      {
        src:Gallery9,
        width:3,
        height:3,
      },
];

export default photos;