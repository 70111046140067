import React from "react";
import EstateBanner from "../img/banner/realestate-banner.jpg";
import RealEstate1 from "../img/project/farm1.jpg";
import RealEstate2 from "../img/project/farm2.jpg";
import RealEstate3 from "../img/project/farm3.jpg";
import RealEstate4 from "../img/project/farm4.jpg";
import RealEstate5 from "../img/project/farm5.jpg";
import RealEstate6 from "../img/project/farm6.jpg";
import RealEstate7 from "../img/project/farm7.jpg";
import RealEstate8 from "../img/project/farm8.jpg";
import "../Css/Realestate.css";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";
import Agricultural from "../img/real-estate/estate-agricultural.jpg";
import Studio from "../img/real-estate/estate-studio-appt.jpg";
import Dreamland from "../img/real-estate/project-dreamland.png";


const Realestate = () => {
  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Plot for Sale in Thane | Dreamland Resort</title>
        <meta
          name="description"
          content="Unlock the door to your dream home with our prime Plot for Sale in Thane. Explore endless possibilities as you envision and create your ideal living space"
        />
        <meta
          property="og:title"
          content="Plot for Sale in Thane | Dreamland Resort"
        />
        <meta
          property="og:description"
          content="Unlock the door to your dream home with our prime Plot for Sale in Thane. Explore endless possibilities as you envision and create your ideal living space"
        />
         <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/plot-for-sale-thane" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ======= Realestate Banner ======= */}
      <section className="real-estate">
        <div className="real-estate-pattern" />
        <div
          className="Realestate-Banner"
          style={{
            backgroundImage: `linear-gradient(
              120deg,
              rgba(0, 0, 0, 0.5) 0%,
              rgba(0, 0, 0, 0.5) 100%
            ),url(${EstateBanner})`,
          }}
        >
          <div className="container">
            <div className="EstatebannerTxt">
            <h1 style={{color:"#fff", fontSize:'10px'}}>Plot for Sale in Thane</h1>
              <h2>Real Estate</h2>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Suscipit, fugit!
              </p> */}
            </div>
          </div>
        </div>
      </section>
      {/* ===== Real estate Section === */}
      <div className="realEstate-service">
        <div className="container">
          <div class="headTitle">
            <h2>Dreammland Realestate</h2>
            <p>Following the triumphant debut and swift sale of Phase I, we are thrilled to introduce Phase II, ushering in a new chapter of unparalleled opportunities. Phase II presents a diverse array of investment prospects, including NA Plots, Agricultural Farm Plots, and Studio Apartments, meticulously designed to cater to varying preferences and aspirations.

For those seeking to delve into the realm of land ownership, our NA Plots offer the perfect canvas to manifest dreams into reality. Whether envisioning a serene retreat amidst nature's embrace or crafting a bespoke abode, these plots epitomize limitless potential.

Similarly, our Agricultural Farm Plots beckon to those with a penchant for cultivation and agricultural pursuits. Embraced by fertile soil and imbued with the promise of bountiful harvests, these plots stand as a testament to the enduring allure of rural living.

Moreover, nestled within Phase II's offerings are Studio Apartments, offering a harmonious blend of contemporary living and convenience. Ideal for discerning individuals seeking a harmonious balance between urban connectivity and tranquil surroundings, these apartments redefine modern living.

 Don't miss your chance to secure your slice of paradise in Thane's most coveted destination for plot-for-sale-thane.</p>
            {/* <p>
              After the successful launch & sale of Phase I, We present you
              Phase II. With NA Plots, Agricultural Farm Plot and Studio
              Apartment. It is an excellent investment opportunity considering
              it’s in close proximity to the 4 lane Mumbai – Nashik Highway{" "}
            </p> */}
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 mb-3">
              <div className="realEstate-Card">
                <div className="realEstate-Img">
                  <img src={Agricultural} alt="plot-sale" />
                </div>
                <div className="realEstate-Desc">
                  <h4>Dreammland Farms</h4>
                  <p>
                    The plots are free of any legal hassles and are equipped
                    with basic amenities like Plot fencing, water connection,
                    electricity and 24 hours security. Each plot has a separate
                    7/12 extract.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-3">
              <div className="realEstate-Card">
                <div className="realEstate-Img">
                  <img src={Studio} alt="plot-thane" />
                </div>
                <div className="realEstate-Desc">
                  <h4>Non Agriculture Plots</h4>
                  <p>
                  The plots are free of any legal hassles and are equipped with basic amenities like Plot fencing, water connection, electricity and 24 hours security. Each plot has a separate 7/12 extract.
                    {/* Dreammland has its own Club House, Resort, Swimming Pool
                    with Jacuzzi, Indoor Games, Landscape Garden and Play Park,
                    Row House, Bungalow’s, Cottages, + */}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6  mb-3">
              <div className="realEstate-Card">
                <div className="realEstate-Img">
                  <img src={Dreamland} alt="plot-for-sale-thane" />
                </div>
                <div className="realEstate-Desc">
                  <h4>Custom  Bungalows</h4>
                  <p>
                    Customized bungalows with all the basic amenities like Plot
                    fencing, water connection, electricity and 24 hours
                    security, Build your own dream bungalow with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===== Real Estate Project  === */}
      <div className="realEstate-Project">
        <div className="pattern-top" />
      
        <div className="container mt-5">
          <div class="headTitle realHead">
            <h2>Project Highlights</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="EstateProject-list">
                <ul>
                  <li><i className="fa fa-star"></i>Approx. 40 acres of beautiful land.</li>
                  <li><i className="fa fa-star"></i>N.A. Plots.</li>
                  <li><i className="fa fa-star"></i>Agricultural Farm Plots.</li>
                  <li><i className="fa fa-star"></i>Studio Apartments.</li>
                  <li><i className="fa fa-star"></i>Row Houses, Bungalows.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="EstateProject-list">
                <ul>
                  <li><i className="fa fa-star"></i>Residential Flats.</li>
                  <li><i className="fa fa-star"></i>Marriage Hall.</li>
                  <li><i className="fa fa-star"></i>Conference Room.</li>
                  <li><i className="fa fa-star"></i>Pleasant climate.</li>
                  <li><i className="fa fa-star"></i>Beautiful boundary gate.</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="EstateProject-list">
                <ul>
                  <li><i className="fa fa-star"></i>Internal tar road.</li>
                  <li><i className="fa fa-star"></i>Piped water & Electricity.</li>
                  <li><i className="fa fa-star"></i>Security.</li>
                  <li><i className="fa fa-star"></i>Street lights.</li>
                  <li><i className="fa fa-star"></i>Amenities.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pattern-bottom" />
      </div>
      {/* ===== Real Estate Project  === */}
      <div className="realEstate">
        <div className="container">
          <div class="headTitle">
            <h2>Completed Bungalow Projects</h2>
          </div>
          <div className="row">
            <div className="col-md-3 mb-5">
              <div className="Estate-Gallery">
                <img src={RealEstate1} alt="plot-for-sale-thane" />
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="Estate-Gallery">
                <img src={RealEstate2} alt="" />
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="Estate-Gallery">
                <img src={RealEstate3} alt="plot-for-sale-thane" />
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="Estate-Gallery">
                <img src={RealEstate4} alt="plot-for-sale-thane" />
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="Estate-Gallery">
                <img src={RealEstate5} alt="plot-for-sale-thane" />
              </div>
            </div>
            <div className="col-md-3 mb-5">
              <div className="Estate-Gallery">
                <img src={RealEstate6} alt="plot-for-sale-thane" />
              </div>
            </div>
           
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Realestate;
