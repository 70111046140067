import React from "react";
import { MetaTags } from "react-meta-tags";
import "../Css/Activities.css";
import Footer from "../Components/Footer";
import resort from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/2. About us 2.jpg";
// --------------Activities----------------------------------
import Badminton from "../img/activities/activities-badminton.jpg";
// import table from "../img/activities/table tennis.jpeg";
import Cricket from "../img/activities/activities-cricket.jpg";
import Carrom from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Carrom.jpg";
import Chess from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/DSC_0976.jpg";
import Pool from "../img/Clubhouse-20240113T064329Z-001/pooltable.jpeg";
import SwimmingPool from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Swimming_Pool.jpg";
import FormParty from "../img/activities/actvities-foamparty.jpg";
import camping from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/camping.jpeg";
import karaoke from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/karaoke.jpg";
import dam from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/kundamdam.jpeg";
import garden from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Landscape_Garden.jpeg";
import kids from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/child-friendly-garden-1088-x-512.webp";
import dj from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Party_Lawn.jpeg";
// import banquet from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/5. Banquet Hall new.jpg";

// import khokho from "../img/activities/khokho.jpeg";
// import kabadi from "../img/activities/kabadi.webp";
import rain from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Rain_Dance.jpg";
import jacuzzi from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/6Jacuzzi.jpeg";
const Activities = () => {
  return (
    <>
      <MetaTags>
        <title>Pure Jain Resort in Thane | Dreamland Resort</title>
        <meta
          name="description"
          content="Embark on a journey of purity and tranquility at our Pure Jain Resort in Thane. Immerse yourself in serene surroundings, delectable Jain cuisine."
        />
        <meta
          property="title"
          content="Pure Jain Resort in Thane | Dreamland Resort"
        />
        <meta
          property="og:title"
          content="Pure Jain Resort in Thane | Dreamland Resort"
        />
       <meta
          name="og:description"
          content="Embark on a journey of purity and tranquility at our Pure Jain Resort in Thane. Immerse yourself in serene surroundings, delectable Jain cuisine."
        />
        <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/pure-jain-resort" />
      </MetaTags>
      {/* ======= Activities Banner ======= */}
      <section className="activities-section">
        <div className="activities-bottom-pattern" />
        <div className="activitiesBanner">
          <div className="container">
            <div className="activitiesbannerTxt">
            <h1 style={{color:"#fff", fontSize:'10px'}}>Pure Jain Resort in Thane</h1>
              <h2>
                Activities At Dreammland
              </h2>
              {/* <p>
                Amet consectetur adipisicing elit sed do eiusmod tempor
                incididunt adipisicing
              </p> */}
            </div>
          </div>
        </div>
      </section>
      {/* ======== Activities ==== */}
      <section className="about-manager">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="manager-img">
                <img src={resort} alt="Pure Jain Resort" />
              </div>
   
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="manager-desc">
              <h2>A day at Dreammland</h2>
              <p>Welcome to the dreamland resort, a pure jain resort in Thane, The morning starts with Valley or Garden views from the open balcony. After your breakfast, swim in nature at our huge swimming pool with the most panoramic view or visit the natural stream of water, just a stroll away. Dance your heart out at our Rain Dance, splish-splash, and have a Foam Party Bash.

In the dreamland resort, a pure jain resort in Thane, After all the excitement, treat yourself to a homely lunch and relax in the afternoon. While you sip your chai with pakoras, let the chit-chat and planning for the fun games start. Play pool table, table tennis, or carrom at the clubhouse; badminton, cricket, kho-kho, kabaddi, or your favorite games at the adjoining party lawn while the sun starts to set for the day.

As evening approaches, hit the dance floor and showcase your moves, whether it's Bollywood, Hollywood, or Desi Naach. 

Watch cricket, organize events, throw parties, or host karaoke nights in our banquet hall. Revisit your childhood on the party lawn with outdoor games, engage in leisurely chit-chat, gossip, or delve into discussions on global issues in our gazebo or expansive open lawn. We've got the perfect spaces for all your needs!

Don't hesitate – you're in the right place. Book now and seize the opportunity to create lasting memories with your loved ones. Contact us at +91-8425091256 or +91-9324545580. It's not just about the activities; it's about crafting moments that will be cherished for a lifetime.

Enjoy free access to all amenities and activities at dreamland resort, a pure jain resort in Thane, including cozy interiors, breathtaking surroundings, and fun activities.
</p>
            {/* <p>The morning starts with Valley or Garden views from the open balcony. After your breakfast, swim in nature at our huge swimming pool with the most panoramic view or visit the natural stream of water, just a stroll away. Dance your heart out at our Rain Dance, splish-splash, and have a Foam Party Bash.

After all the excitement, treat yourself to a homely lunch and relax in the afternoon. While you sip your chai with pakoras, let the chit-chat and planning for the fun games start. Play pool table, table tennis, or carrom at the clubhouse; badminton, cricket, kho-kho, kabaddi, or your favorite games at the adjoining party lawn while the sun starts to set for the day.

As evening approaches, hit the dance floor and showcase your moves, whether it's Bollywood, Hollywood, or Desi Naach. 

Watch cricket, organize events, throw parties, or host karaoke nights in our banquet hall. Revisit your childhood on the party lawn with outdoor games, engage in leisurely chit-chat, gossip, or delve into discussions on global issues in our gazebo or expansive open lawn. We've got the perfect spaces for all your needs!

Don't hesitate – you're in the right place. Book now and seize the opportunity to create lasting memories with your loved ones. Contact us at +91-8425091256 or +91-9324545580. It's not just about the activities; it's about crafting moments that will be cherished for a lifetime.

Enjoy free access to all amenities and activities at Dreammland Resort, including cozy interiors, breathtaking surroundings, and fun activities.</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-section">
        <div className="container">
          <div class="headTitle">
          
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${SwimmingPool})`,
                  }}
                >
                  
                </div>
                <div className="si-text">
                  <h4>Swimming</h4>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${rain})`,
                  }}
                >
                 
                </div>
                <div className="si-text">
                  <h4>Rain Dance</h4>
              


                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${splash})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Splish Splash</h4>
                  
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${FormParty})`,
                  }}
                >
                
                </div>
                <div className="si-text">
                  <h4>Foam Party</h4>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${Cricket})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Cricket</h4>
                
                </div>
              </div>
            </div>
          
           
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${Badminton})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Badminton</h4>
                 
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                 className="si-pic"
                 style={{
                   backgroundImage:
                     `url(${Carrom})`,
                 }}
                >
                </div>
                <div className="si-text">
                  <h4>Carrom</h4>
                 
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                 className="si-pic"
                 style={{
                   backgroundImage:
                     `url(${Chess})`,
                 }}
                >
                </div>
                <div className="si-text">
                  <h4>Chess</h4>
                
                </div>
              </div>
            </div>


            {/* ---------------------indoor games------------- */}
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                 className="si-pic"
                 style={{
                   backgroundImage:
                     `url(${Pool})`,
                 }}
                >
                </div>
                <div className="si-text">
                  <h4>Pool Table</h4>
                
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${table})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Table Tennis</h4>

                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${jacuzzi})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Jacuzzi</h4>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${camping})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Camping</h4>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${kids})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Kids Play Area</h4>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${karaoke})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Karaoke</h4>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${garden})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Landscape Garden</h4>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${dj})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>DJ Party Lawn</h4>
                
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${dam})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Kundan Dam</h4>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Activities;
