import React from "react";
import "../Css/Room.css";
import Footer from "../Components/Footer";
import Roomlist from "../Components/RoomList";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { MetaTags } from "react-meta-tags";

const Room = () => {
  // ======= Fetch Services with Slugs in same page =======
  const search = useLocation();
  const Path = search.pathname;
  const RoomDesc = Roomlist.find((e) => e.Slugs == Path);
  console.log(RoomDesc.Rooms[0]);
  const Room1 = RoomDesc.Rooms[0];
  const Room2 = RoomDesc.Rooms[1];
  const Room3 = RoomDesc.Rooms[2];
  // const Room4 = RoomDesc.Rooms[3];    
  return (
    <>
     {/* ========= Seo start========== */}
     <MetaTags>
        <title>Resort with Private Room in Thane | Dreammland Resort</title>
        <meta
          name="description"
          content="Experience luxury like never before at our Resort with a Private Room in Thane. Immerse yourself in comfort, top-notch amenities, and a stay."
        />
        <meta
          property="title"
          content="Resort with Private Room in Thane | Dreammland Resort"
        />
        <meta
          property="og:title"
          content="Resort with Private Room in Thane | Dreammland Resort"
        />
       <meta
          name="og:description"
          content="Experience luxury like never before at our Resort with a Private Room in Thane. Immerse yourself in comfort, top-notch amenities, and a stay."
        />
        <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/resort-private-rooms-thane" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ======= Room Banner ======= */}
      <section className="Room-section">
        <div className="room-bottom-pattern" />
        <div
          className="room-Banner"
          style={{
            backgroundImage: `linear-gradient(
              120deg,
              rgba(0, 0, 0, 0.5) 0%,
              rgba(0, 0, 0, 0.5) 100%
            ),url(${RoomDesc.ServiceImage})`,
          }}
        >
          <div className="container">
            <div className="roombannerTxt">
            <h1 style={{color:"#fff", fontSize:'10px'}}>Resort with Private Room in Thane</h1>
              <h2>{RoomDesc.Header}</h2>
              <p>{RoomDesc.ShortDesc}</p>
            </div>
          </div>
        </div>
      </section>


      {/* ===== Real estate Section === */}
     <div className="realEstate-service">
        <div className="container">
          <div class="headTitle">
            <h2>Private Rooms</h2>
            <p>
            Welcome to the dreamland resort with private rooms in Thane.A home away from home for a Couple, Family or a group of friends. Valley views or Garden views from the open balcony. Whether you're here for a short staycation or an extended getaway, our dreamland resort with private rooms in Thane provides a comfortable experience. All the amenities and restaurants are just a walk away.


            


            </p>

          </div>
        
        </div>
      </div>
      {/* ===== Room Details1 ======= */}
      <section className="room-services1">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6">
              <div className="Room-Desc">
                <div className="Room-Txt">
                  <h2>{Room1.Header1}</h2>
                  <p>{Room1.ParaDesc1}</p>
                  <div className="room-aminities">
                    <h4>Room Amenities</h4>
                    <div className="row">
                        {Room1.Amenities.map((AmenitiesValue) => (
                          <div className="col-lg-6 col-md-12 col-sm-6">
                            <div className="room-aminitesList">
                              <ul>
                                <li>
                                  <span>
                                    <i className="fa fa-check"></i>
                                  </span>
                                  {AmenitiesValue.RoomAmenities1}
                                </li>
                              </ul>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="link-box mt-3">
                <Link to="/top-resort-thane" className="theme-btn btn-style-one">
                  <span className="btn-title">Enquire Now</span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                className="projSwiper"
              >
                {Room1.imgData.map((imgValue) => (
                  <SwiperSlide>
                    <div className="Room-Img">
                      <img src={imgValue.roomImg} alt="Resort with Private Room"/>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* ===== Room Details2 ======= */}
      <section className="room-services2">
        <div className="pattern-top" />
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 order-md-1 order-2">
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                className="projSwiper"
              >
                {Room2.imgData.map((imgValue) => (
                  <SwiperSlide>
                    <div className="Room-Img">
                      <img src={imgValue.roomImg} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-lg-6 col-md-6 order-md-2 order-1">
              <div className="Room-Desc">
                <div className="Room-Txt">
                  <h2>{Room2.Header2}</h2>
                  <p>{Room2.ParaDesc2}</p>
                  <div className="room-aminities">
                    <h4>Room Amenities</h4>
                    <div className="row">
                      {Room2.Amenities.map((AmenitiesValue) => (
                        <div className="col-lg-6 col-md-12 col-sm-6">
                          <div className="room-aminitesList">
                            <ul>
                              <li>
                                <span>
                                  <i className="fa fa-check"></i>
                                </span>
                                {AmenitiesValue.RoomAmenities1}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="link-box mt-3">
                    <Link to="/top-resort-thane" className="theme-btn btn-style-one">
                  <span className="btn-title">Enquire Now</span>
                </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pattern-bottom" />
      </section>
      {/* ===== Room Details3 ======= */}
      <section className="room-services3">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6">
              <div className="Room-Desc">
                <div className="Room-Txt">
                  <h2>{Room3.Header3}</h2>
                  <p>{Room3.ParaDesc3}</p>
                  <div className="room-aminities">
                    <h4>Room Amenities</h4>
                    <div className="row">
                      {Room3.Amenities.map((AmenitiesValue) => (
                        <div className="col-lg-6 col-md-12 col-sm-6">
                          <div className="room-aminitesList">
                            <ul>
                              <li>
                                <span>
                                  <i className="fa fa-check"></i>
                                </span>
                                {AmenitiesValue.RoomAmenities1}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="link-box mt-3">
                    <Link to="/top-resort-thane" className="theme-btn btn-style-one">
                  <span className="btn-title">Enquire Now</span>
                </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                className="projSwiper"
              >
                {Room3.imgData.map((imgValue) => (
                  <SwiperSlide>
                    <div className="Room-Img">
                      <img src={imgValue.roomImg} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      
      {/* ========= Enquiry Form ===== */}
      <Footer />
    </>
  );
};

export default Room;
