import "./App.css";
import NavBar from "./Components/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Activities from "./Pages/Activities";
import Room from "./Pages/Room";
import Contact from "./Pages/Contact";
import RoomList from "./Components/RoomList";
import Realestate from "./Pages/Realestate";
import Resort from "./Pages/Resort";
import Wedding from "./Pages/Wedding";
// import RoomDetailPage from "./Pages/RoomDetailPage";
import RoomData from "./Components/RoomData";
import CottageList from "./Pages/CottageList";
import RoomServices from "./Components/RoomServices";
import Errorpage from './Components/Errorpage';
function App() {
  // Initialize an array to store Slug objects
const slugObjectsArray = [];

// Iterate over the RoomList and extract Slugs as objects
RoomList.forEach((roomCategory) => {
  roomCategory.Rooms.forEach((room) => {
    slugObjectsArray.push({ slug: room.Slugs });
  });
});

console.log(slugObjectsArray);
RoomData.forEach((roomCategory) => {
  roomCategory.Cott.forEach((room) => {
    slugObjectsArray.push({ slug: room.Slugs });
  });
});
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pure-veg-resorts-thane" element={<About />} />
          <Route path="/pure-jain-resort" element={<Activities />} />
          
          <Route path="/resort-for-weddings-thane" element={<Wedding />} />
          <Route path="/top-resort-thane" element={<Contact />} />
          
          <Route path ="/plot-for-sale-thane" element={<Realestate/>}/>
          <Route path='/*' element={<Errorpage/>} />
          {RoomList.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<Room/>}
            />
          ))}
  
{/* {slugObjectsArray.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<RoomDetailPage/>}
            />
          ))} */}
          {RoomData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<CottageList/>}
            />
          ))}
          {RoomServices.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<Resort/>}
            />
          ))}
        </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
