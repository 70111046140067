import React, { useState, useEffect } from "react";
import "../Css/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
// import { GrFacebookOption, GrInstagram } from "react-icons/gr";
// import { RiWhatsappFill } from "react-icons/ri";
// import { IoCall } from "react-icons/io5";
// import Dropdown from "react-bootstrap/Dropdown";
import RoomData from "./RoomData";
import RoomList from "./RoomList";
import RoomServices from "./RoomServices";

const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className="nav-bar-component">
       
        <div className="NavSection">
          <div className="container-fluid">
            <Navbar
              collapseOnSelect
              expand="lg"
              className={color ? "NavBar NavBar-bg" : "NavBar"}
            >
              <Container>
                <Navbar.Brand href="/">
                  <img src={logo} alt="jain-food-resort-thane" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="justify-content-start"
                >
                  <Nav>
                    <Nav.Link href="/">
                      <Link to="/">Home</Link>
                    </Nav.Link>
                    <Nav.Link href="/pure-veg-resorts-thane">
                      <Link to="/pure-veg-resorts-thane">About</Link>
                    </Nav.Link>
                    <Nav.Link href="/pure-jain-resort">
                      <Link to="/pure-jain-resort">Activities</Link>
                    </Nav.Link>
                    <button className="dropdownShow">
                      Resort
                      <div className="dorpdown">
                        <div className="dropdown_list">
                        {RoomList.map((ele) => {
                            const { Header, Slugs } = ele;
                            return (
                              <>
                              
                                <Nav.Link href={`${Slugs}`}>
                                  <Link  to={`${Slugs}`}>{Header}</Link>
                                </Nav.Link>
                              </>
                            );
                          })}
                          {RoomData.map((ele) => {
                            const { Heading, Slugs } = ele;
                            return (
                              <>
                              
                                <Nav.Link href={`${Slugs}`}>
                                  <Link  to={`${Slugs}`}>{Heading}</Link>
                                </Nav.Link>
                              </>
                            );
                          })}
                                 {RoomServices.map((ele) => {
                            const { Header, Slugs } = ele;
                            return (
                              <>
                              
                                <Nav.Link href={`${Slugs}`}>
                                  <Link  to={`${Slugs}`}>{Header}</Link>
                                </Nav.Link>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </button>
                    {/* <Nav.Link href="/Resort">
                      <Link to="/Resort">Resort</Link>
                    </Nav.Link> */}
                    <Nav.Link href="/plot-for-sale-thane">
                      <Link to="/plot-for-sale-thane">Real Estate</Link>
                    </Nav.Link>
                    <Nav.Link href="/resort-for-weddings-thane">
                      <Link to="/resort-for-weddings-thane">Weddings</Link>
                    </Nav.Link>
                  
                    <Nav.Link href="/top-resort-thane">
                      <Link to="/top-resort-thane">Contact</Link>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
