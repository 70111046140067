import React from 'react'

import "../Css/Room.css";
import Footer from "../Components/Footer";
import RoomData from "../Components/RoomData";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { MetaTags } from "react-meta-tags";

const CottageList = () => {
  // ======= Fetch Services with Slugs in same page =======
  const search = useLocation();
  const Path = search.pathname;
  const RoomListdata = RoomData.find((e) => e.Slugs == Path);
  
  const Cott1 = RoomListdata.Cott[0];
  const Cott2 = RoomListdata.Cott[1];
  const Cott3 = RoomListdata.Cott[2];
  const Cott4 = RoomListdata.Cott[3];
  console.log(RoomListdata.Cott[0]);
  return (
    <>
     {/* ========= Seo start========== */}
     <MetaTags>
        <title>Resort with Cottages Room in Thane | Dreamland Resort</title>
        <meta
          name="description"
          content="Indulge in the perfect blend of comfort and charm at our Resort with a Cottages Room in Thane. Immerse yourself in luxury amidst nature, creating memories."
        />
        <meta
          property="title"
          content="Resort with Cottages Room in Thane | Dreamland Resort"
        />
        <meta
          property="og:title"
          content="Resort with Cottages Room in Thane | Dreamland Resort"
        />
       <meta
          name="og:description"
          content="Indulge in the perfect blend of comfort and charm at our Resort with a Cottages Room in Thane. Immerse yourself in luxury amidst nature, creating memories."
        />
        <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/resort-cottages-room-thane" />
      </MetaTags>
      {/* ======== Seo end ======= */}
    {/* ======= Room Banner ======= */}
    <section className="Room-section">
      <div className="room-bottom-pattern" />
      <div
        className="room-Banner"
        style={{
          backgroundImage: `linear-gradient(
            120deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
          ),url(${RoomListdata.RoomImage})`,
        }}
      >
        <div className="container">
          <div className="roombannerTxt">
          <h1 style={{color:"#fff", fontSize:'10px'}}>{RoomListdata.Tagline}</h1>

            <h2>{RoomListdata.Heading}</h2>
            <p>{RoomListdata.ShortDesc}</p>
          </div>
        </div>
      </div>
    </section>

     {/* ===== Real estate Section === */}
     <div className="realEstate-service">
        <div className="container">
          <div class="headTitle">
            <h2>Cottages</h2>
            <p>
            Welcome to our dreamland resort with cottage rooms in Thane. A long drive from Mumbai takes you to this dignified two-level living space; a home away from home for a group of family, friends or colleagues. a resort with cottage rooms in Thane offers spacious 3BHK and 4BHK cottages designed for large groups of 10 to 25 people. Valley views from the open balcony. All the amenities and restaurants are just a walk away. Enjoy the serenity of private outdoor spaces, personalized service, and the unique charm that sets our cottages apart. 
            


            </p>

          </div>
        
        </div>
      </div>
    {/* ===== Room Details1 ======= */}
    <section className="room-services1">
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6">
            <div className="Room-Desc">
              <div className="Room-Txt">
                <h2>{Cott1.Header1}</h2>
                <p>{Cott1.ParaDesc1}</p>
                <div className="room-aminities">
                  <h4>Room Amenities</h4>
                  <div className="row">
                      {Cott1.Amenities1.map((AmenitiesValue) => (
                        <div className="col-lg-6 col-md-12 col-sm-6">
                          <div className="room-aminitesList">
                            <ul>
                              <li>
                                <span>
                                  <i className="fa fa-check"></i>
                                </span>
                                {AmenitiesValue.RoomAmenities}
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="link-box mt-3">
              <Link to={Room1.Slugs} className="theme-btn btn-style-one">
                <span className="btn-title">View More</span>
              </Link>
            </div> */}
          </div>
          <div className="col-lg-6 col-md-6">
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              // className="projSwiper"
            >
              {Cott1.imgData1.map((imgValue) => (
                <SwiperSlide>
                  <div className="Room-Img">
                    <img src={imgValue.roomImg} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    {/* ===== Room Details2 ======= */}
    <section className="room-services2">
      <div className="pattern-top" />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 order-md-1 order-2">
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              className="projSwiper"
            >
              {Cott2.imgData1.map((imgValue) => (
                <SwiperSlide>
                  <div className="Room-Img">
                    <img src={imgValue.roomImg} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="col-lg-6 col-md-6 order-md-2 order-1">
            <div className="Room-Desc">
              <div className="Room-Txt">
                <h2>{Cott2.Header2}</h2>
                <p>{Cott2.ParaDesc2}</p>
                <div className="room-aminities">
                  <h4>Room Amenities</h4>
                  <div className="row">
                    {Cott2.Amenities1.map((AmenitiesValue) => (
                      <div className="col-lg-6 col-md-12 col-sm-6">
                        <div className="room-aminitesList">
                          <ul>
                            <li>
                              <span>
                                <i className="fa fa-check"></i>
                              </span>
                              {AmenitiesValue.RoomAmenities}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className="link-box mt-3">
                    <Link to={Cott2.Slugs} className="theme-btn btn-style-one">
                      <span className="btn-title">View More</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pattern-bottom" />
    </section>
    {/* ===== Room Details3 ======= */}
    <section className="room-services3">
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6">
            <div className="Room-Desc">
              <div className="Room-Txt">
                <h2>{Cott3.Header3}</h2>
                <p>{Cott3.ParaDesc3}</p>
                <div className="room-aminities">
                  <h4>Room Amenities</h4>
                  <div className="row">
                    {Cott3.Amenities1.map((AmenitiesValue) => (
                      <div className="col-lg-6 col-md-12 col-sm-6">
                        <div className="room-aminitesList">
                          <ul>
                            <li>
                              <span>
                                <i className="fa fa-check"></i>
                              </span>
                              {AmenitiesValue.RoomAmenities}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className="link-box mt-3">
                    <Link to={Room3.Slugs} className="theme-btn btn-style-one">
                      <span className="btn-title">View More</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              className="projSwiper"
            >
              {Cott3.imgData1.map((imgValue) => (
                <SwiperSlide>
                  <div className="Room-Img">
                    <img src={imgValue.roomImg} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    {/* ===== Room Details4 ======= */}
    <section className="room-services2">
      <div className="pattern-top" />
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 order-md-1 order-2">
            <Swiper
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              modules={[Autoplay, Navigation, Pagination]}
              className="projSwiper"
            >
              {Cott4.imgData1.map((imgValue) => (
                <SwiperSlide>
                  <div className="Room-Img">
                    <img src={imgValue.roomImg} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="col-lg-6 col-md-6 order-md-2 order-1">
            <div className="Room-Desc">
              <div className="Room-Txt">
                <h2>{Cott4.Header4}</h2>
                <p>{Cott4.ParaDesc4}</p>
                <div className="room-aminities">
                  <h4>Room Amenities</h4>
                  <div className="row">
                    {Cott4.Amenities1.map((AmenitiesValue) => (
                      <div className="col-lg-6 col-md-12 col-sm-6">
                        <div className="room-aminitesList">
                          <ul>
                            <li>
                              <span>
                                <i className="fa fa-check"></i>
                              </span>
                              {AmenitiesValue.RoomAmenities}
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className="link-box mt-3">
                    <Link to={Room4.Slugs} className="theme-btn btn-style-one">
                      <span className="btn-title">View More</span>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pattern-bottom" />
    </section>
    {/* ========= Enquiry Form ===== */}
    <Footer />
  </>
  );
};




 

export default CottageList