import Hotel from "../img/HOTEL ROOMS-20240113T064308Z-001/2DeluxeRoom.jpg";
import Hotel1 from "../img/HOTEL ROOMS-20240113T064308Z-001/3. Executive Room 2.jpg";
import Hotel2 from "../img/HOTEL ROOMS-20240113T064308Z-001/2DeluxeRoom.jpg";
import Hotel3 from "../img/private-rooms/1. Family Room 2.jpg";
import Hotel4 from "../img/private-rooms/1. Family Room 1.jpg"
const RoomList = [
  {
    id: 1,
    ServiceImage: Hotel,
    Header: "Private Rooms",
    Slugs: "/resort-private-rooms-thane",
    ShortDesc:"A home away from home for a Couple, Family or a group of friends. Valley views or Garden views from the open balcony.",
      // "A home away from home for a Couple, Family or a group of friends. Valley views or Garden views from the open balcony. Whether you're here for a short staycation or an extended getaway, our hotel rooms provide a comfortable experience. All the amenities and the Dining Hall are just a walk away.",
    Rooms: [
      {
        Header1: "Executive Rooms (Rooms for 2)",
        Slugs: "/executive-rooms",
        ParaDesc1:
          "Nature is the purest portal of inner peace. All rooms in this category face the valley/greenery and are well-appointed to make your stay comfortable. Our Executive rooms are designed to accommodate 2 people.",
          MetaTitle: "Resort with Private Room in Thane | Dreamland Resort",
          MetaDes:  "Experience luxury like never before at our Resort with a Private Room in Thane. Immerse yourself in comfort, top-notch amenities, and a stay.",
          CanonicalTag: "https://dreammland.com/resort-private-rooms-thane",
          imgData: [{ roomImg: Hotel1 }],
        Amenities: [
          {
            RoomAmenities1: "Valley View Bedroom",
          },
          {
            RoomAmenities1: "1 couple beds",
          },
          {
            RoomAmenities1: "Air conditioning",
          },
          {
            RoomAmenities1: "Complimentary Water",
          },
          {
            RoomAmenities1: "Kettle",
          },
          {
            RoomAmenities1: "Towels & Soaps",
          },
          {
            RoomAmenities1: "Free Parking",
          },
          {
            RoomAmenities1: "All meals included based on your package",
          },
        ],
      },
      {
        Header2: "Deluxe Rooms (Rooms for 3 or 4)",
        Slugs: "/deluxe-rooms",
        ParaDesc2:
          "Relax in total comfort in these well-appointed rooms. Designed to accommodate more than 2 people, our Deluxe Rooms are elegantly furnished. You’ll also find everything you need to relax and enjoy your holidays at the place blessed by nature.",
        imgData: [{ roomImg: Hotel2 }],
        Amenities: [
          {
            RoomAmenities1: "Valley View / Garden View Bedroom",
          },
          {
            RoomAmenities1: "1 couple bed",
          },
          {
            RoomAmenities1: "1 single bed",
          },
          {
            RoomAmenities1: "Extra Mattresses",
          },
          {
            RoomAmenities1: "Air conditioning",
          },
          {
            RoomAmenities1: "Complimentary Water",
          },
          {
            RoomAmenities1: "Kettle",
          },
          {
            RoomAmenities1: "Towels & Soaps",
          },
          {
            RoomAmenities1: "Free Parking",
          },
          {
            RoomAmenities1: "All meals included based on your package",
          },
        ],
      },
      {
        Header3: "Family Rooms (Rooms for 4+)",
        Slugs: "/family-rooms",
        ParaDesc3:
          "Together is the wonderful place to be. Family rooms offer a perfect private space to make life-long memories with your mates. These rooms are designed to accommodate 4 to 8 people so that you can spend maximum time with your group of weirdos.",
        imgData: [{ roomImg: Hotel3,roomImg:Hotel4 }],
        Amenities: [
          {
            RoomAmenities1: "Valley View Bedroom",
          },
          {
            RoomAmenities1: "2 couple bed",
          },
          {
            RoomAmenities1: "Extra Mattresses",
          },
          {
            RoomAmenities1: "Air conditioning",
          },
          {
            RoomAmenities1: "Complimentary Water",
          },
          {
            RoomAmenities1: "Kettle",
          },
          {
            RoomAmenities1: "Towels & Soaps",
          },
          {
            RoomAmenities1: "Free Parking",
          },
          {
            RoomAmenities1: "All meals included based on your package",
          },
        ],
      },
    ],
  },

  // {
  //   id: 2,
  //   ServiceImage: Cottages,
  //   Header: "Cottages",
  //   Slugs: "/cottages-rooms",
  //   ShortDesc:
  //     "A long drive from Mumbai takes you to this dignified two-level living space; a home away from home for a group of family, friends or colleagues. We offer spacious 3BHK and 4BHK cottages with large living rooms designed for large groups of 10 to 25 people. Valley views from the open balcony. All the amenities and the Dining Hall just a walk away. ",
  //   Rooms: [
  //     {
  //       Header1: "Cottage 1",
  //       Slugs: "/cottages-one",
  //       ParaDesc1:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 3 bedrooms huge balcony with a sunset view, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers.",
  //         imgData: [{ roomImg: Cottages1_3}, { roomImg: Cottages1_2 },{ roomImg: Cottages1_3 },{ roomImg: Cottages1_4 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "3 Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Television",
  //         },
  //         {
  //           RoomAmenities1: "Huge Terrace with the sunset view",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //       ],
  //     },
  //     {
  //       Header2: "Cottage 2",
  //       Slugs: "/cottage-two",
  //       ParaDesc2:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 4 bedrooms and 2 living rooms, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers.",
  //       imgData: [{ roomImg: Cott1 }, { roomImg: Cott2 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Master Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Multi-Purpose Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Dressing Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Television",
  //         },
  //       ],
  //     },
  //     {
  //       Header3: "Cottage 3",
  //       Slugs: "/cottage-three",
  //       ParaDesc3:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 4 bedrooms and 2 living rooms, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers",
  //       imgData: [{ roomImg: Cottages3 }, { roomImg: Cottages3 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Master Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "6 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Multi-Purpose Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Dressing Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Fridge & Television",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //       ],
  //     },
  //     {
  //       Header4: "Cottage 4",
  //       Slugs: "/cottage-four",
  //       ParaDesc4:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 4 bedrooms and 2 living rooms, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers.",
  //       imgData: [{ roomImg: Cottages4}, { roomImg: Cott4_1 },{ roomImg: Cott4_2 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Master Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "6 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Multi-Purpose Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Dressing Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Fridge & Television",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //       ],
  //     },
    
  //   ],
  // },
  
  // {
  //   id: 3,
  //   ServiceImage: Cottages,
  //   Header: "Custom Villa",
  //   Slugs: "/custom-villa",
  //   ShortDesc:
  //     "A long drive from Mumbai takes you to this dignified two-level living space; a home away from home for a group of family, friends or colleagues. We offer spacious 3BHK and 4BHK cottages with large living rooms designed for large groups of 10 to 25 people. Valley views from the open balcony. All the amenities and the Dining Hall just a walk away. ",
  //    Rooms: [
  //     {
  //       Header1: "Cottage 1",
  //       Slugs: "/cottages-one",
  //       ParaDesc4:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 3 bedrooms huge balcony with a sunset view, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers.",
  //       imgData: [{ roomImg: Cottages1 }, { roomImg: Cottages1 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "3 Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Television",
  //         },
  //         {
  //           RoomAmenities1: "Huge Terrace with the sunset view",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //       ],
  //     },
  //     {
  //       Header2: "Cottage 2",
  //       Slugs: "/cottage-two",
  //       ParaDesc2:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 4 bedrooms and 2 living rooms, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers.",
  //       imgData: [{ roomImg: Cottages2 }, { roomImg: Cottages2 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Master Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Multi-Purpose Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Dressing Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Television",
  //         },
  //       ],
  //     },
  //     {
  //       Header3: "Cottage 3",
  //       Slugs: "/cottage-three",
  //       ParaDesc3:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 4 bedrooms and 2 living rooms, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers",
  //       imgData: [{ roomImg: Cottages3 }, { roomImg: Cottages3 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Master Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "6 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Multi-Purpose Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Dressing Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Fridge & Television",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //       ],
  //     },
  //     {
  //       Header4: "Cottages 4",
  //       Slugs: "/cottage-four",
  //       ParaDesc4:
  //         "The Duplex Cottages offer you an unmatched combination of privacy and luxury with 4 bedrooms and 2 living rooms, the cottages are spacious. And of course the balconies overlooking the hills are simply perfect for family get-togethers.",
  //       imgData: [{ roomImg: Cottages4 }, { roomImg: Cottages4 }],
  //       Amenities: [
  //         {
  //           RoomAmenities1: "2 big Living Rooms",
  //         },
  //         {
  //           RoomAmenities1: "4 Master Bed Rooms",
  //         },
  //         {
  //           RoomAmenities1: "6 Wash Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Multi-Purpose Rooms",
  //         },
  //         {
  //           RoomAmenities1: "2 Dressing Rooms",
  //         },
  //         {
  //           RoomAmenities1: "Air conditioning",
  //         },
  //         {
  //           RoomAmenities1: "Fridge & Television",
  //         },
  //         {
  //           RoomAmenities1: "Balconies with a breath-taking view",
  //         },
  //       ],
  //     },
  //     // {
  //     //   Header1: "Cottages Room",
  //     //   Slugs: "/cottages",
  //     //   ParaDesc1:
  //     //     "One, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, harum.",
  //     //   imgData: [{ roomImg: Cottages4 }, { roomImg: Cottages4 }],
  //     //   Amenities: [
  //     //     {
  //     //       RoomAmenities1: "Valley View Bedroom",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "2 couple beds",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "Extra Mattresses",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "Air conditioning",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "Complimentary Water",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "Kettle",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "Towels & Soaps",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "All meals included based on your package",
  //     //     },
  //     //     {
  //     //       RoomAmenities1: "Free Parking",
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  
  
];

export default RoomList;
