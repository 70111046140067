import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Css/Common.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "../Css/Home.css";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import RoomData from "../Components/RoomList";
import About from "../img/HOTEL ROOMS-20240113T064308Z-001/Dreammland Hotel.jpeg";
// -----------Activities-------------------------
import Badminton from "../img/activities/activities-badminton.jpg";
import table from "../img/activities/table tennis.jpeg";
import Cricket from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/DSC_1061.JPG";
import Carrom from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Carrom.jpg";
import Chess from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/DSC_1066.JPG";
import Pool from "../img/Clubhouse-20240113T064329Z-001/pooltable.jpeg";
import SwimmingPool from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Swimming_Pool.jpg";
import FormParty from "../img/activities/actvities-foamparty.jpg";
import camping from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/camping.jpeg";
import banquet from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/5. Banquet Hall new.jpg";
import lawn from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Party_Lawn.jpeg";
// import khokho from "../img/activities/khokho.jpeg";
// import kabadi from "../img/activities/kabadi.webp";
import villa from "../img/villa/IMG-20190721-WA0007.jpg"
import rain from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/Rain_Dance.jpg";
import jacuzzi from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/6Jacuzzi.jpeg";

import cottages from "../img/FINAL COTTAGE 2-20240113T064309Z-001/FINAL COTTAGE 2/OUTSIDEVIEWCOTTAGE2.jpg";
import proom from "../img/HOTEL ROOMS-20240113T064308Z-001/2DeluxeRoom.jpg";

import Icon1 from "../img/icon/dish.png";
import Icon2 from "../img/icon/secure.png";
import Icon3 from "../img/icon/location.png";
import Icon4 from "../img/icon/calendar.png";
import Icon5 from "../img/icon/tray.png";
import Icon6 from "../img/icon/bed.png";

const Home = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      {/* ========= Seo start========== */}
      <MetaTags>
        <title>Best Resort in Thane | Dreammland Resort</title>
        <meta
          name="description"
          content="Escape to serenity at the Best Resort in Thane. Indulge in luxury and relaxation, surrounded by nature's beauty. Plan your perfect getaway today."
        />
        <meta
          property="title"
          content="Best Resort in Thane | Dreammland Resort"
        />
        <meta
          property="og:title"
          content="Best Resort in Thane | Dreammland Resort"
        />
       <meta
          name="og:description"
          content="Escape to serenity at the Best Resort in Thane. Indulge in luxury and relaxation, surrounded by nature's beauty. Plan your perfect getaway today."
        />
        <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ======= Home Banner ======= */}
      <section className="banner-section banner-one">
        <div className="banner-bottom-pattern" />
        <div className="banner-carousel">
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="Home_Banner"
          >
            <SwiperSlide>
              <div className="HomeBanner1">
                <div className="container">
                  <div className="bannerTxt">
                    <h1 style={{color:"white", fontSize:'12px'}}>Best Resort in Thane</h1>
                    <h2>
                    Welcome To Dreammland
                    </h2>
                    {/* <p>
                      Amet consectetur adipisicing elit sed do eiusmod tempor
                      incididunt adipisicing
                    </p> */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="HomeBanner2">
                <div className="container">
                  <div className="bannerTxt">
                    <h2>
                    Treat at The Dreammland
                    </h2>
                    {/* <p>
                      Amet consectetur adipisicing elit sed do eiusmod tempor
                      incididunt adipisicing
                    </p> */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="HomeBanner3">
                <div className="container">
                  <div className="bannerTxt">
                    <h2>
                    Welcome Dreammland Resort
                    </h2>
                    {/* <p>
                      Amet consectetur adipisicing elit sed do eiusmod tempor
                      incididunt adipisicing
                    </p> */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* === About Section ===== */}
      <section className="about-section mt-5">
        <div className="pattern-bottom" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-img">
                <img src={About} alt="Best Resort in Thane" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="home-about-txt">
                <h2>Your Gateway to Tranquility Amidst Nature!</h2>
                <p>
                Just 100 kilometers from Mumbai, this best Resort in Thane is located amidst picturesque landscapes, surrounded by majestic mountains, and overlooking the tranquil Kundan dam - where luxury meets tranquillity. 
A swift 2-hour drive from Mumbai towards Igatpuri takes you to this perfect staycation for your next weekend getaway. Dreamland Resort is located in Shirol Gaon, Kundan Pada, which is a trending tourist spot and officially declared a hill station by the Government. 
Whether you seek relaxation by our pristine swimming pool, indulgence in our Vegetarian and Jain dining, or adventure in the surrounding natural beauty, our pocket-friendly resort promises an unforgettable experience. 
Whether you're planning a dreamy destination wedding, organizing a corporate event, looking for a new place for pre-wedding photoshoots or seeking a budget-friendly option for social gatherings, your search ends here. Dreamland, the best Resort in Thane has a perfect backdrop to elevate your celebrations and corporate events. 
Different styles of accommodation to choose from. Spacious villas for huge groups. Couple rooms. Family rooms. Whether a romantic retreat, staycation with friends, or family adventure. A space to call your own. All the amenities and the restaurant, just a walk away. Now stay at the best resort in Thane.

                </p>
                <div className="mt-5">
                  <Link to="/pure-veg-resorts-thane" className="theme-btn btn-style-one">
                    <span className="btn-title">Read More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Room ======= */}
      <section className="rooms-section-one">
        <div className="container">
          <div class="headTitle">
            <h2>Accomodations</h2>
            {/* <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi
              tempora distinctio rerum libero laudantium assumenda.
            </p> */}
          </div>
          {/* <div className="row">
            {RoomData.map((item, index) => {
              const { id, ServiceImage, Header, ShortDesc, Slugs } = item;
              return (
                <>
                  <div className="column col-lg-4 col-md-6 col-sm-12">
                    <div className="room-block-one height-one wow fadeInUp">
                      <div className="inner-box">
                        <div className="image-box">
                          <div
                            className="image-layer"
                            style={{
                              backgroundImage: `url(${ServiceImage})`,
                            }}
                          />
                        </div>
                        <div className="cap-box">
                          <div className="cap-inner">
                            <h4>{Header}</h4>
                          </div>
                        </div>
                        <div className="hover-box">
                          <div className="hover-inner">
                            <h4>{Header}</h4>
                            <div className="text">{ShortDesc}</div>
                            <div className="link-box">
                              <Link
                                to={`${Slugs}`}
                                className="theme-btn btn-style-one"
                              >
                                <span className="btn-title">
                                  Read More
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div> */}
          <div className="row">
          <div className="column col-lg-4 col-md-6 col-sm-12">
              <div className="room-block-one height-one wow fadeInUp">
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{
                        backgroundImage: `url(${proom})`,
                      }}
                    />
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      <h4>Private Rooms</h4>
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>Private Rooms</h4>
                      <div className="text">
                      Discover comfort with valley or garden views in our cozy hotel rooms. Ideal for couples, families, or friends on a short staycation or extended getaway. All amenities and the dining hall are just steps away. Your home away from home awaits!
                      </div>
                      <div className="link-box">
                        <Link
                          to="/resort-private-rooms-thane"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Read More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="column col-lg-4 col-md-6 col-sm-12">
              <div className="room-block-one height-one wow fadeInUp">
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{
                        backgroundImage: `url(${cottages})`,
                      }}
                    />
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      <h4>COTTAGES</h4>
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>COTTAGES</h4>
                      <div className="text">
                      
                      Mumbai escape:  Our best resort in Thane offers spacious 3BHK and 4BHK cottages with large living rooms designed for large groups of 10 to 25 people. 
                    </div>
                      <div className="link-box">
                        <Link
                          to="/resort-cottages-room-thane"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Read More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column col-lg-4 col-md-6 col-sm-12">
              <div className="room-block-one height-one wow fadeInUp">
                <div className="inner-box">
                  <div className="image-box">
                    <div
                      className="image-layer"
                      style={{
                        backgroundImage: `url(${villa})`,
                      }}
                    />
                  </div>
                  <div className="cap-box">
                    <div className="cap-inner">
                      <h4>Private Villa</h4>
                      
                    </div>
                  </div>
                  <div className="hover-box">
                    <div className="hover-inner">
                      <h4>Private Villa</h4>
                      <br/>
                      <p>YOUR DREAM HOLIDAY HOME AWAITS</p>
                      <div className="text">
                      Dreammland:Ever wondered what it would be like to have your very own villa, designed by you, in the heart of a serene getaway.
                      </div>
                      <div className="link-box">
                        <Link
                          to="/best-private-villa-thane"
                          className="theme-btn btn-style-one"
                        >
                          <span className="btn-title">Read More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </div>
      </section>
      {/* ======== Services ======= */}
      <section className="Service-sec">
        <div className="pattern-top" />
        <div className="container">
          <div class="headTitle">
            <h2>Features</h2>
            <p>
              With the everlasting beauty that stood for many years comes a fine
              resort to serve its name
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 mt-5">
              <div className="service-desc">
                <div className="services-icon">
                  <img
                    src={Icon2}
                    alt="Best Resort in Mumbai"
                  />
                </div>
                <div className="service-txt">
                  <h4>Varied Stay Options</h4>
                  <p>
                  Spacious cottages for groups. Couple rooms. Family rooms. Whether romantic retreat, getaway with friends or family adventure. A space to call your own.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-5">
              <div className="service-desc">
                <div className="services-icon">
                <img
                    src={Icon1}
                    alt="Best Resort in Thane"
                  />
                </div>
                <div className="service-txt">
                  <h4>Fresh Food</h4>
                  <p>
                  Vegetarian and Jain dietary preferences. Indian as well as International cuisine. A feast to taste buds and satisfaction to soul! 

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-5">
              <div className="service-desc">
                <div className="services-icon">
                <img
                    src={Icon3}
                    alt="Best Resort"
                  />
                </div>
                <div className="service-txt">
                  <h4>Best In Class Location</h4>
                  <p>
                  
                  Spacious surrounds. Fresh breeze. Your stay nested in greenery. Tall mountains. Green gardens. Swing. Serene.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-5">
              <div className="service-desc">
                <div className="services-icon">
                <img
                    src={Icon4}
                    alt="Resort in Thane"
                  />
                </div>
                <div className="service-txt">
                  <h4> Perfect destination for your events</h4>
                  <p>
                 
                  Whether you're planning a dreamy destination wedding, looking for a new place for photoshoots, organizing a corporate event, your search ends here. We have perfect backdrop to elevate your events. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-5">
              <div className="service-desc">
                <div className="services-icon">
                <img
                    src={Icon5}
                    alt="Resort in mumbai"
                  />
                </div>
                <div className="service-txt">
                  <h4>Friendly & Responsive Staff</h4>
                  <p>
                  
                  Our commitment to impeccable service is woven into every aspect of your stay. Our dedicated team is poised to anticipate and exceed your need.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-5">
              <div className="service-desc">
                <div className="services-icon">
                <img
                    src={Icon6}
                    alt="Best Resort in Thane"
                  />
                </div>
                <div className="service-txt">
                  <h4>Easy Reservation</h4>
                  <p>
                  You contact our booking staff and they will guide you ahead. Simple! Book Now!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="service-Btn mt-5">
            <Link to="/Realestate" className="theme-btn btn-style-one">
              <span className="btn-title">View More</span>
            </Link>
          </div> */}
        </div>
        <div className="pattern-bottom" />
      </section>
      {/* ========== Activities ======= */}
      <section className="Activities-Sec">
        <div className="container">
          
          <div className="row">
            <div className="col-md-6">
              <div className="home-activities-txt">
                <h2> Activities at Dreammland</h2>
                <p>
                Dreammland the best Resort in Thane offers a paradise of amenities, including a vast scenic pool and Jacuzzi, dynamic rain dances, and foam parties. With a clubhouse for indoor gaming, a Banquet Hall, and a sprawling Party Lawn, outdoor enthusiasts can revel in spacious lawns for games or enjoy a tranquil walk amid majestic mountains and trees.

{/* Dreammland Resort offers a paradise of amenities, including a vast scenic pool and Jacuzzi, dynamic rain dances, and foam parties. With a clubhouse for indoor gaming, a Banquet Hall, and a sprawling Party Lawn, outdoor enthusiasts can revel in spacious lawns for games or enjoy a tranquil walk amid majestic mountains and trees. */}
                </p>
                <div className="link-box">
                  <Link to="/pure-jain-resort" className="theme-btn btn-style-one">
                    <span className="btn-title">View More</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[ Navigation]}
                className="ActivitieSwiper"
              > 
              {/* <SwiperSlide>
              <div className="activitie-Img">
                <img src={banquet} alt="Best Resort in Thane" />
                <div className="activities-txt">
                  <h4>Banquet</h4>
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={lawn} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Party Lawn</h4>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={SwimmingPool} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Swimming Pool</h4>
                    </div>
                  </div>
                </SwiperSlide>
               
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={Carrom} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Carrom</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={Chess} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Chess</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={Pool} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Pool Table</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={jacuzzi} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Jacuzzi</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={rain} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Rain Dance</h4>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={Badminton} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Badminton</h4>
                    </div>
                  </div>
                </SwiperSlide>
               
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={Cricket} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Cricket</h4>
                    </div>
                  </div>
                </SwiperSlide>
                
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={camping} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Camping</h4>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={FormParty} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Form Party</h4>
                    </div>
                  </div>
                </SwiperSlide> 
                <SwiperSlide>
                  <div className="activitie-Img">
                    <img src={table} alt="Best Resort in Thane" />
                    <div className="activities-txt">
                      <h4>Table Tennis</h4>
                    </div>
                  </div>
                </SwiperSlide> 
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      {/* ===== Testional === */}
      <section className="testimonials-section">
        <div className="pattern-top" />
        <div className="container">
          <div class="headTitle">
            <h2>Testimonal</h2>
            {/* <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi
              tempora distinctio rerum libero laudantium assumenda.
            </p> */}
            
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="Testimonal"
          >
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        The resort's jacuzzi swimming pool is a true highlight, providing relaxation and fun for all. 
                        As a vegetarian, I was thrilled to see an extensive selection of pure vegetarian and Jain food options, 
                        catering to various tastes. The staff deserves a special mention for their warmth and professionalism. 
                        Our group had a blast with the exciting activities they offered, creating unforgettable memories. 
                        The rooms were well-appointed and cleaned regularly, ensuring a comfortable stay. And the icing on 
                        the cake was the mesmerizing tracking point with the water dam view, surrounded by the beauty of 
                        majestic mountains. It's a dream destination!"

                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="image">
                       
                      </div>
                      <div className="name">GEETA BHANUSHALI</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        This place is a paradise for group vacations! The amenities are outstanding with a jacuzzi 
                        swimming pool that adds a touch of luxury to your stay. As a pure vegetarian, I was thrilled to 
                        find that they also cater to Jain dietary preferences, ensuring everyone's needs are met. 
                        The staff here are incredibly polite and attentive, making us feel right at home. Our group 
                        had a blast participating in various activities offered by the resort. The rooms were 
                        well-maintained and spotlessly clean, ensuring a comfortable and enjoyable stay. Moreover, 
                        the highlight of our trip was the breathtaking tracking point with a stunning water dam view. 
                        The sight of the majestic mountains was simply awe-inspiring. Highly recommended!"

                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="image">
                        {/* <img
                          src="http://t.commonsupport.com/hotera/images/resource/testi-thumb-2.jpg"
                          alt=""
                        /> */}
                      </div>
                      <div className="name">JUGAL MEHTA</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">  
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        "Our group had an amazing time at this place! The facilities were top-notch, and the jacuzzi 
                        swimming pool added an extra touch of luxury to our vacation. Being pure vegetarians, 
                        we were delighted to find a variety of Jain food options available, making our dining 
                        experience perfect. The staff was generally polite and attentive, although there were a 
                        few instances of delayed service. However, it didn't overshadow the fun we had with the 
                        numerous activities available. The rooms were good, but could use a bit of improvement in 
                        terms of cleanliness. Nonetheless, the breathtaking tracking point near the water dam was 
                        unforgettable, and we cherished the panoramic mountain views."

                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="image">
                        {/* <img
                          src="http://t.commonsupport.com/hotera/images/resource/testi-thumb-2.jpg"
                          alt=""
                        /> */}
                      </div>
                      <div className="name">Komal Jethva</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        The property is very homely and beautiful....The villa gives you a serene feeling, very 
                        neat and clean...The food is very delicious. They have jain and non non jain food...care was 
                        taken to serve both cuisines with our choices being their priority....
                        The hospitality was great and we felt very special...
                     Especially during monsoon you get an amazing weather and one can trek towards the dam and 
                     find a hidden waterfall

                        </div>
                      </div>
                    </div>
                    <div className="info">
                      <div className="image">
                        {/* <img
                          src="http://t.commonsupport.com/hotera/images/resource/testi-thumb-2.jpg"
                          alt=""
                        /> */}
                      </div>
                      <div className="name">Chirag Gangani</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className="pattern-bottom" /> */}
      </section>
      <Footer />
    </>
  );
};

export default Home;
