import React, {useState} from "react";
import { Link } from "react-router-dom";
import "../Css/Footer.css";
import Logo from "../img/logo.png";
import $ from "jquery";
const Footer = () => {
  const [errors, setErrors] = useState({
    custName: "",
    custEmail: "",
    custContact: "",
  
    custMessage: "",
  });

  const handleValidation = () => {
    let isValid = true;
    const newErrors = {
      custName: "",
      custEmail: "",
      custContact: "",
      custMessage: "",
    };
    if (!custName.trim()) {
      isValid = false;
      newErrors.custName = "Name is required";
    }

    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!custEmail.match(emailRegex)) {
      isValid = false;
      newErrors.custEmail = "Email is not valid";
    }

    if (!custContact.trim()) {
      isValid = false;
      newErrors.custContact = "Phone is required";
    } else if (!/^\d{10}$/.test(custContact)) {
      isValid = false;
      newErrors.custContact = "Phone must have 10 digits";
    }
   

    if (!custMessage.trim()) {
      isValid = false;
      newErrors.custMessage = "Write a Message";
    }

    setErrors(newErrors);
    return isValid;
  };
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");

  const [custMessage, setCustMessage] = useState("");
  // ============== Mail Print ======
  const ServForm = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      var body =
        '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Dreammland Resort</h2><p>Hello Dreammland Resort,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custContact +
        
        '</td></tr><tr><td style="text-align:left"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Shree Krishna Digital Marketing</span>.</p></div></body></html>';
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "mcshah.dreammland@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName:"dreammland",
          accountLeadSource: "",
          accountLeadName: custName,
          // accountLeadEmail: custEmail,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
          console.log("name:" + custName);
          console.log("name:" + custEmail);
        }
      );

      alert("Your Form has Submitted Our team will contact with You  soon.");
      e.preventDefault();
      return false;
    }
  };
  return (
    <>
      <footer className="footer-section">
        <div className="container">
         
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link href="/">
                      <img
                        src={Logo}
                        className="img-fluid"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                    Dreammland Resort, 
Near Kundan Lake, Kundan Pada, 
Shirol, Maharashtra - 421602
                    </p>
                    <p><span>Reservation & Enquiries :</span>
                    +91-84250 91256                    </p>
                    <p><span>For Events: </span>
                    +91-93245-45580</p>
                    <p><span>Lobby Manager: </span>
                    +91 86910 82257                 </p>

                    <br/>

                    <p><span>  Web : </span>
                    www.dreammland.com                </p>
                    <p><span> Reservation: </span>
                    mcshah.dreammland@gmail.com               </p>


                  </div>
                 
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/pure-veg-resorts-thane">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/pure-jain-resort">
                        Activities
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/">
                      Room
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/Resort">
                        Resort
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/plot-for-sale-thane">
                        Real EState
                      </Link>
                    </li>
                    <li>
                      <Link to="/resort-for-weddings-thane">
                        Wedding
                      </Link>
                    </li>
                    <li>
                      <Link to="/top-resort-thane">Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-text">
                  <p>Dreammland Resort In Mumbai - Rooms 
Banquet & Conference Halls near Mumbai </p><p>
Destination Wedding Venues near Mumbai </p><p>
Adventure Resorts near Mumbai </p><p>
Resort with Jain Food near Mumbai</p>
                </div>
                <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.facebook.com/dreammlandresortofficial">
                      <i className="fa fa-facebook facebook-bg" />
                    </a>
                  
                    <a href="https://instagram.com/dreammland_resort_/">
                      <i className="fa fa-instagram google-bg" />
                    </a>
                    <a href="https://wa.me/+918425091256">
                      <i class="fa fa-whatsapp watsapp-bg" />
                    </a>
                  </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  
                  
                <>
  {/* Hello world */}
 
  {/* footer Section Begins */}
 
          <div className="contact-form btm-brdr">
            <div className="form-Box">
              <input type="text" placeholder="Name"   value={custName}
                        onChange={(e) => setCustName(e.target.value)} className="b_effect" />
            </div>
            {errors.custName && (
                        <span className="error-text">{errors.custName}</span>
                      )}
            <div className="form-Box">
              <input type="text" placeholder="Email"    value={custEmail}
                        onChange={(e) => setCustEmail(e.target.value)} className="b_effect" />
            </div>
            {errors.custEmail && (
                        <span className="error-text">{errors.custEmail}</span>
                      )}
            <div className="form-Box">
              <input type="number" placeholder="Phone"    value={custContact}
                          onChange={(e) => setCustContact(e.target.value)} className="" />
            </div>
            {errors.custContact && (
                          <span className="error-text">{errors.custSubject}</span>
                        )}
            
            <div className="form-Box">
              {" "}
              <textarea
                placeholder="Message"
                className="b_effect"
                defaultValue={""}
                value={custMessage}
                onChange={(e) => setCustMessage(e.target.value)}
              />
            </div>
            {errors.custMessage && (
                          <span className="error-text">{errors.custMessage}</span>
                        )}
            <div className="form-Box">
              <input type="submit" defaultValue="Submit" onClick={ServForm} className="fill-btn" />
            </div>
          </div>
        {/* </div>
       
      </div>
    </div>
  </section> */}
  {/* footer Section Ends */}
  {/* copyright Section Begins */}
 
</>

                  {/* <div className="footer-widget-heading">
                    <h3>Address</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <h5>Marketing Office</h5>
                    <p>
                      Yashh mansion infrastructure pvt Ltd (Dreammland Resort).
                      22/1/1, Vallabh Socity, 90 feet Road, Pantnagar, Above
                      Jalaram Plywood, Ghatkopar (E). Mumbai 75
                    </p>
                  </div>
                  <div className="footer-text mb-25">
                    <h5>Site Office</h5>
                    <p>
                    Dreammland, Near Kundan Dam, Kundan Pada, Sirol Gaon, Shahpur, Thane.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2023, All Right Reserved{" "}
                  
                      Dreammland Resort
                    
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 text-center text-right">
              <div className="copyright-text">
                  <p>
                    Develope By : 
                    <a href="https://skdm.in">
                       Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
