import Service1 from "../img/room/home-hotel.jpeg";
import Service2 from "../img/room/home-cottages.jpg";
import Cottages from "../img/room/home-cottages.jpg";
import Cottages1 from "../img/cottage/cottage1.jpg";
import Cottages1_2 from "../img/cottage/cottage1.jpg";
import Cottages1_3 from "../img/cottage/cottage1_3.jpg";
import Cottages1_4 from "../img/cottage/cottage1_4.jpg";
import cottage2_1 from "../img/FINAL COTTAGE 2-20240113T064309Z-001/FINAL COTTAGE 2/LIVING ROOM 1_1 GROUND FLOOR COTTAGE 2.jpg"
import cottage2_2 from "../img/FINAL COTTAGE 2-20240113T064309Z-001/FINAL COTTAGE 2/LIVING ROOM 2_1 FIRST FLOOR COTTAGE 2.jpg"
import cottage2_3 from "../img/FINAL COTTAGE 2-20240113T064309Z-001/FINAL COTTAGE 2/R3-C2-1FLR.jpg"
import cottage2_4 from "../img/FINAL COTTAGE 2-20240113T064309Z-001/FINAL COTTAGE 2/OUTSIDE IMAGE _1 COTTAGE 3.jpg"
import cottage3_1 from "../img/FINAL COTTAGE 3-20240113T064312Z-001/FINAL COTTAGE 3/Hall2-C3-1FLR 1.jpg"
import cottage3_2 from "../img/FINAL COTTAGE 3-20240113T064312Z-001/FINAL COTTAGE 3/LIVING ROOM 1_2 GROUND FLOOR COTTAGE 3.jpg"
import cottage3_3 from "../img/FINAL COTTAGE 3-20240113T064312Z-001/FINAL COTTAGE 3/R1-C3-GLR 1.jpg"
import cottage3_4 from "../img/FINAL COTTAGE 3-20240113T064312Z-001/FINAL COTTAGE 3/OUTSIDE IMAGE _1 COTTAGE 3.jpg"
import cottage4_1 from "../img/FINAL COTTAGE 4-20240113T064318Z-001/FINAL COTTAGE 4/LIVING ROOM 1+1 FIRST FLOOR COTTAGE 4.jpg"
import cottage4_2 from "../img/FINAL COTTAGE 4-20240113T064318Z-001/FINAL COTTAGE 4/OUTSIDE IMAGE _1 COTTAGES.jpg"
import cottage4_3 from "../img/FINAL COTTAGE 4-20240113T064318Z-001/FINAL COTTAGE 4/R2-C4-GFLR 2.jpg"
import cottage4_4 from "../img/FINAL COTTAGE 4-20240113T064318Z-001/FINAL COTTAGE 4/R3-C4-1FLR.jpg"

const RoomData = [
 {
    id: 1,
    RoomImage: Service2,
    ShortDesc:"A long drive from Mumbai takes you to this dignified two-level living space.A resort with cottage rooms in Thane offers spacious 3BHK and 4BHK cottages designed for large groups of 10 to 25 people.",
    // ShortDesc:"A long drive from Mumbai takes you to this dignified two-level living space; a home away from home for a group of family, friends or colleagues. We offer spacious 3BHK and 4BHK cottages designed for large groups of 10 to 25 people. Valley views from the open balcony. All the amenities and restaurant just a walk away. Enjoy the serenity of private outdoor spaces, personalized service, and the unique charm that sets our cottages apart. ",
   Tagline:"Resort with cottages in Thane",
    Slugs: "/resort-cottages-room-thane",
    Heading: "Cottages",
    MetaTitle: "Resort with Cottages Room in Thane | Dreamland Resort",
    MetaDes:  "Indulge in the perfect blend of comfort and charm at our Resort with a Cottages Room in Thane. Immerse yourself in luxury amidst nature, creating memories.",
    CanonicalTag: "https://dreammland.com/resort-cottages-room-thane",
    Cott:[
    {
      Header1:"Cottage1",
      Slugs:"/cottage-one",
      ParaDesc1:
        "Ideal for 10 to 15 people, this Duplex Villa features 3 bedrooms, 2 spacious living rooms, a huge balcony with a sunset view and free access to all amenities and activities. The cottages are spacious. And of course, the balconies overlooking the hills are simply perfect for family get-togethers. Book your stay today for the ultimate group getaway experience.",
        imgData1: [{ roomImg: Cottages1_3}, { roomImg: Cottages1_2 },{ roomImg: Cottages1_3 },{ roomImg: Cottages1_4 }],
      Amenities1: [
        {
          RoomAmenities: "2 big Living Rooms",
        },
        {
          RoomAmenities: "3 Bed Rooms",
        },
        {
          RoomAmenities: "4 Wash Rooms",
        },
        {
          RoomAmenities: "Air conditioning",
        },
        {
          RoomAmenities: "Television",
        },
        {
          RoomAmenities: "Huge Terrace with the sunset view",
        },
        {
          RoomAmenities: "Balconies with a breath-taking view",
        },
      ],
    },
  {
    Header2: "Cottage 2",
    ParaDesc2:
    
      "Ideal for 15 to 18 people, this Duplex 4BHK Villa features 4 bedrooms, 2 spacious living areas, ample indoor and outdoor spaces, and free access to all amenities and activities. These villas provide ample space for bonding and relaxation. The tastefully decorated interiors create a warm and inviting atmosphere for your group to enjoy. Book your stay today for the ultimate group getaway experience.",
    imgData1: [{ roomImg: cottage2_3 }, { roomImg: cottage2_2 } ,{ roomImg: cottage2_1 }, { roomImg: cottage2_4 }],
    Slugs:"/cottage-two",
    Amenities1: [
      {
        RoomAmenities: "2 big Living Rooms",
      },
      {
        RoomAmenities: "4 Master Bed Rooms",
      },
      {
        RoomAmenities: "4 Wash Rooms",
      },
      {
        RoomAmenities: "2 Multi-Purpose Rooms",
      },
      {
        RoomAmenities: "2 Dressing Rooms",
      },
      
      {
        RoomAmenities: "Air conditioning",
      },
      {
        RoomAmenities: "Television",
      },
    ],
  },
  {
    
    Header3: "Cottage 3",
    imgData1: [{ roomImg: cottage3_1 }, { roomImg: cottage3_2 }, { roomImg: cottage3_3 }, { roomImg: cottage3_4 }],
    ParaDesc3:"Ideal for 18 to 20 people, this Duplex 4BHK Villa features 4 bedrooms, 2 spacious living areas, ample indoor and outdoor spaces, and free access to all amenities and activities.These villas provide ample space for bonding and relaxation. The tastefully decorated interiors create a warm and inviting atmosphere for your group to enjoy. Secure your booking today for the ultimate group getaway experience at Dreammland Resort..",
    Slugs:"/cottage-three",
    Amenities1: [
      {
        RoomAmenities: "2 big Living Rooms",
      },
      {
        RoomAmenities: "4 Master Bed Rooms",
      },
      {
        RoomAmenities: "6 Wash Rooms",
      },
      {
        RoomAmenities: "2 Multi-Purpose Rooms",
      },
      {
        RoomAmenities: "2 Dressing Rooms",
      },
      {
        RoomAmenities: "Air conditioning",
      },
      {
        RoomAmenities: "Fridge & Television",
      },
      
    ],
  },
  {
    
    Header4: "Cottage 4",
    ParaDesc4:"Ideal for 18 to 20 people, this 4BKH Duplex Villa features 4 bedrooms, 2 spacious living areas, ample indoor and outdoor spaces, and free access to all amenities and activities.These villas provide ample space for bonding and relaxation. The tastefully decorated interiors create a warm and inviting atmosphere for your group to enjoy. Secure your booking today for the ultimate group getaway experience at Dreammland Resort.",
    Slugs:"/cottage-four",
    imgData1: [{ roomImg: cottage4_1 }, { roomImg: cottage4_2 },{ roomImg: cottage4_3},{ roomImg: cottage4_4 }],
    Amenities1: [
      {
        RoomAmenities: "2 big Living Rooms",
      },
      {
        RoomAmenities: "4 Master Bed Rooms",
      },
      {
        RoomAmenities: "6 Wash Rooms",
      },
      {
        RoomAmenities: "2 Multi-Purpose Rooms",
      },
      {
        RoomAmenities: "2 Dressing Rooms",
      },
      {
        RoomAmenities: "Air conditioning",
      },
      {
        RoomAmenities: "Fridge & Television",
      },
      
    ],
  },
  
  ],
    
},
   
 
 
];

export default RoomData;
  