import Service1 from "../img/restro/WhatsApp Image 2023-02-17 at 12.27.45 (1).jpeg";
import Service2 from "../img/restro/WhatsApp Image 2023-02-17 at 12.27.44.jpeg";
import Service3 from "../img/villa/IMG-20190731-WA0187.jpg";
import Service4 from "../img/room/home-bungalow.jpg";

const RoomServices = [
  {
    id: 1,
    ServiceImage: Service4,
    Tagline:"Best Private Villa in Thane",
    Header: "Private Villa",
    Slugs: "/best-private-villa-thane",
    ShortDesc:
      "Ever wondered what it would be like to have your very own villa, designed by you, in the heart of a serene getaway? Dreammland makes this dream a reality by offering plots ready for your dream holiday home.",
    ParaDesc:"Ever wondered what it would be like to have your very own villa, designed by you, in the heart of a serene getaway? Dreammland best private villa in Thane makes this dream a reality by offering plots ready for your dream holiday home. Imagine the freedom to build a haven that matches your style and desires. Our plots are surrounded by nature, creating the perfect backdrop for your retreat. Dreammland best private villa in Thane is your partner in turning your vision into a charming reality, giving you not just a plot but a canvas for your ideal holiday escape.",
      // "Ever wondered what it would be like to have your very own villa, designed by you, in the heart of a serene getaway? Dreammland makes this dream a reality by offering plots ready for your dream holiday home. Imagine the freedom to build a haven that matches your style and desires. Our plots are surrounded by nature, creating the perfect backdrop for your retreat. Dreammland is your partner in turning your vision into a charming reality, giving you not just a plot but a canvas for your ideal holiday escape.",
      MetaTitle: "Best Private Villa in Thane | Dreamland Resort",
      MetaDes:  "Indulge in opulence at the Best Private Villa in Thane. Experience unparalleled comfort, exquisite amenities, and a retreat like no other.",
      CanonicalTag: "https://dreammland.com/best-private-villa-thane",
    imgData: [{ roomImg: Service4 }, { roomImg: Service3 }],
  },
  {
  id: 2,
    ServiceImage: Service4,
    Tagline:"Best Resort with Dinning in Thane",
    Header: "Dinning",
    Slugs: "/best-resort-dining-thane",
    ShortDesc:
      "At Dreammland Resort, we cater to pure vegetarian and Jain dietary preferences with a thoughtfully curated menu. Our chefs create homely, flavorful dishes using fresh, locally sourced ingredients, ensuring a delightful dining experience. From customizable options to international flavors, guests can enjoy the comfort of familiar tastes and the warmth of well-balanced vegetarian and Jain meals during their stay. We also offer special menus for events, ensuring a personalized and satisfying culinary journey.",
    ParaDesc:"At Dreammland Resort, we pride ourselves on offering the best resort dining experience in Thane, tailored specifically to cater to pure vegetarian and Jain dietary preferences. Our carefully crafted menu showcases a diverse range of homely, flavorful dishes created by our talented chefs, who use only the freshest, locally sourced ingredients to ensure a delightful culinary journey for our guests.Whether you're craving customizable options or international flavors, our menu has something to satisfy every palate. Guests can indulge in the comfort of familiar tastes while savoring the warmth of well-balanced vegetarian and Jain meals throughout their stay. Furthermore, for special events, we go above and beyond by providing personalized menus, guaranteeing a truly unforgettable dining experience for all our guests.At Dreammland Resort, we're dedicated to delivering the epitome of excellence in resort dining, making us the best resort dining choice in Thane.",
      // "At Dreammland Resort, we cater to pure vegetarian and Jain dietary preferences with a thoughtfully curated menu. Our chefs create homely, flavorful dishes using fresh, locally sourced ingredients, ensuring a delightful dining experience. From customizable options to international flavors, guests can enjoy the comfort of familiar tastes and the warmth of well-balanced vegetarian and Jain meals during their stay. We also offer special menus for events, ensuring a personalized and satisfying culinary journey.",
      MetaTitle: "Best Resort with Dinning in Thane | Dreamland Resort",
      MetaDes:  "Savor culinary delights at the Best Resort with Dining in Thane. Immerse yourself in luxury, impeccable service, and delectable cuisine.",
      CanonicalTag: "https://dreammland.com/best-resort-dining-thane",
      imgData: [{ roomImg: Service1 }, { roomImg: Service2 }],
    
  },

];

export default RoomServices;
