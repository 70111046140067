import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ProjectImages from "../Components/Photo";
import Enquiry from "../Components/Enquiry";
import {Link} from "react-router-dom";
import "../Css/Wedding.css";
import CountUp from "react-countup";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Icon1 from "../img/icon/dish.png";
import Icon2 from "../img/icon/secure.png";
import Icon3 from "../img/icon/location.png";
import Icon4 from "../img/icon/calendar.png";
import Icon5 from "../img/icon/tray.png";
import Icon6 from "../img/icon/bed.png";
import Mehandi from "../img/project/farm1.jpg";

import wedding3 from "../img/wedding/wedding3.jpg";
import wedding4 from "../img/wedding/wedding4.jpg";
import wedding5 from "../img/wedding/wedding5.jpg";
import wedding6 from "../img/wedding/wedding6.jpg";
import wedding7 from "../img/wedding/wedding7.jpg";
import wedding8 from "../img/wedding/wedding8.jpg";
import wedding9 from "../img/wedding/wedding9.jpg";
import wedding10 from "../img/wedding/wedding10.jpg";
import wedding11 from "../img/wedding/wedding11.jpg";
import wedding12 from "../img/wedding/wedding12.jpg";
import wedding13 from "../img/wedding/wedding13.jpg";
import wedding14 from "../img/wedding/wedding14.jpg";

import haldi from "../img/wedding/Haldi.jpg";
import poolP from "../img/wedding/PoolParty.jpg";
import reception from "../img/wedding/Reception.jpg";
import sangeet from "../img/wedding/Sangeet6.jpg";
import carnival from "../img/wedding/carnivalHaldi.jpg";
import wedding from "../img/wedding/WeddingSangeetReception.jpg";

import celebration1 from "../img/events/Celebrations1.webp";
import celebration2 from "../img/events/Celebrations2.jpg";
import celebration3 from "../img/events/Celebrations3.jpg";
import corporate1 from "../img/events/corporate1.jpeg";
import corporate2 from "../img/events/CorporateEvents2.jpg";
import corporate3 from "../img/events/Celebrations3.jpg";
import social1 from "../img/events/social1.jpeg";
import social2 from "../img/events/social2.png";
import social3 from "../img/events/social3.jpg";


const Wedding = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

    // popup Modal
    const [modalShow, setModalShow] = React.useState(false);

    const [expanded, setExpanded] = React.useState("panel1");
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
     {/* ========= Seo start========== */}
     <MetaTags>
        <title>Resort for Weddings in Thane | Dreamland Resort</title>
        <meta
          name="description"
          content="Create lasting memories at our exquisite Resort for Weddings in Thane. From picturesque venues to impeccable service, embark on your journey of love."
        />
        <meta
          property="title"
          content="Resort for Weddings in Thane | Dreamland Resort"
        />
        <meta
          property="og:title"
          content="Resort for Weddings in Thane | Dreamland Resort"
        />
       <meta
          name="og:description"
          content="Create lasting memories at our exquisite Resort for Weddings in Thane. From picturesque venues to impeccable service, embark on your journey of love. "
        />
        <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/resort-for-weddings-thane" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      {/* ======= Wedding Banner ======= */}
      <section className="wedding-section">
        <div className="wedding-bottom-pattern" />
        <div className="weddingBanner">
          <div className="container">
            <div className="weddingbannerTxt">
            <h1 style={{color:"#fff", fontSize:'10px'}}>Resort for Weddings in Thane</h1>

              <h2>
                Dream Celebrations
              </h2>
              {/* <p>
                Amet consectetur adipisicing elit sed do eiusmod tempor
                incididunt adipisicing
              </p> */}
            </div>
          </div>
        </div>
      </section>
      {/* ======== wedding Services ======= */}
      <section className="Service-sec">
        <div className="pattern-top" />
        <div className="container">
          <div class="headTitle">
            <h2>
                Dream Celebrations</h2>
            <p>
            Whether you're envisioning a dreamy destination wedding, a jubilant birthday party, or a joyous family reunion, our resort provides the perfect backdrop for your special moments. Additionally, Dreammland Resort is well-equipped to host seamless and successful corporate events, offering a versatile and inspiring venue for meetings, conferences, and team-building activities. Discover the perfect setting that caters to your needs without compromising on your budget. Look no further; your ideal destination awaits!
            </p>
          </div>
        
        </div>
       
      </section>
      {/* ========== Wedding Counter ===== */}
      <section className="room-services">
        <div className="container">
   
          <div className="row mt-5">
         
            <div className="col-lg-6 col-md-6">
              <div className="Room-Desc">
                <div className="Room-Txt">
                  <h2>Destination Wedding</h2>
                  <p>Looking for the perfect backdrop for a romantic and picturesque destination wedding? Look no further. Make your special day truly magical amidst the natural beauty of Dreammland Resort for weddings in Thane. It is a perfect wedding venue near Mumbai. Our newly renovated spaces seamlessly blend affordability with natural beauty, creating the ideal canvas for your dream wedding. Whether you envision an intimate ceremony or a grand celebration with friends and family, the Dreammland Resort for weddings in Thane Team is here to assist you every step of the way, ensuring a stress-free wedding day and allowing you to focus on the magic of the moment.
</p>
                  {/* <p>Looking for the perfect backdrop for a romantic and picturesque destination wedding? Look no further. Make your special day truly magical amidst the natural beauty of Dreammland Resort. It is a perfect wedding venue near Mumbai. Our newly renovated spaces seamlessly blend affordability with natural beauty, creating the ideal canvas for your dream wedding. Whether you envision an intimate ceremony or a grand celebration with friends and family, the Dreammland Team is here to assist you every step of the way, ensuring a stress-free wedding day and allowing you to focus on the magic of the moment.</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 ">
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[ Autoplay, Navigation, Pagination]}
                className="projSwiper"
              >
               
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding3}  alt="resort-for-weddings-thane"/>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding4}  alt=""/>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                  <div className="Room-Img wed-img">
                      <img src={wedding5}  alt=""/>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding6}  alt=""/>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding7}  alt=""/>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding8}  alt=""/>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding9}  alt=""/>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding10} alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding11} alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding12} alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding13} alt="" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="Room-Img wed-img">
                      <img src={wedding14} alt="" />
                    </div>
                  </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 col-sm-6 mt-3">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${carnival})`,
                  }}
                >
                  {/* <div className="service-icon">
                    <img src={SwimVest} alt="Jacuzzi" />
                  </div> */}
                </div>
                <div className="text-center">
                  <h4>Carnival with Mehendi 

</h4>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-3">
              <div className="services-item">
                <div
                 className="si-pic"
                 style={{
                   backgroundImage:
                     `url(${poolP})`,
                 }}
                >
                </div>
                <div className="si-text">
                  <h4>Pool Party</h4>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-3">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:`url(${haldi})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4> Haldi</h4>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-3">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:`url(${sangeet})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4>Sangeet</h4>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-3 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:`url(${wedding})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4> Wedding Vows</h4>
                 
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mt-3 ">
              <div className="services-item">
                <div
                  className="si-pic"
                  style={{
                    backgroundImage:
                      `url(${reception})`,
                  }}
                >
                </div>
                <div className="si-text">
                  <h4> Reception</h4>
               
                </div>
              </div>
            </div>
           
          </div>
          <div className="row mt-5">
          <div className="col-lg-6 col-md-6">
           <Swiper
             autoplay={{
               delay: 3000,
               disableOnInteraction: false,
             }}
             pagination={{ clickable: true }}
             navigation={true}
             modules={[Autoplay, Navigation, Pagination]}
             className="projSwiper"
           >
            
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={celebration1} />
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={celebration2} />
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={celebration3} />
                 </div>
               </SwiperSlide>
           
           </Swiper>
         </div>
         <div className="col-lg-6 col-md-6">
           <div className="Room-Desc">
             <div className="Room-Txt ml-1">
               <h2>Celebrations</h2>
               <p>Celebrate birthdays, anniversaries, baby shower and more at our resort! Enjoy special moments in a picturesque setting with personalized packages, scenic views, and dedicated event spaces. Our team ensures a seamless experience, taking care of every detail so you can focus on creating lasting memories with your loved ones.</p>
               {/* <p>Celebrate birthdays, anniversaries, baby shower and more at our resort! Enjoy special moments in a picturesque setting with personalized packages, scenic views, and dedicated event spaces. Our team ensures a seamless experience, taking care of every detail so you can focus on creating lasting memories with your loved ones. </p> */}
             </div>
           </div>
         </div>
         
       </div>
       <div className="row mt-5">
         
         <div className="col-lg-6 col-md-6 order-lg-1 order-2">
           <div className="Room-Desc">
             <div className="Room-Txt">
               <h2>Social Events</h2><p>Host your social and community gatherings at our resort! Our versatile spaces are perfect for events of all sizes, be it a community meeting, reunion, or social event. 
We've successfully hosted a variety of camps and gatherings, including Naturopathy Camps, Rotary Group’s Medical Camps, events of Macchukatha Jain Sangh, outings of Pantnagar Paathshala kids, Patel Samaj gatherings, and many more. We have an in-house Jain Derasar / Jain Temple, where you can host your poojans.
<p></p>We offer tailored packages, excellent amenities, and a welcoming atmosphere. Let us take care of your needs, allowing you to focus on connecting with your community. Elevate your gatherings with scenic views and top-notch service, all at a budget-friendly price for larger groups.
</p>
               {/* <p>Host your social and community gatherings at our resort! Our versatile spaces are perfect for events of all sizes, be it a community meeting, reunion, or social event. 
We've successfully hosted a variety of camps and gatherings, including Naturopathy Camps, Rotary Group’s Medical Camps, events of Macchukatha Jain Sangh, outings of Pantnagar Paathshala kids, Patel Samaj gatherings, and many more. We have an in-house Jain Derasar / Jain Temple, where you can host your poojans.</p>
          <p>We offer tailored packages, excellent amenities, and a welcoming atmosphere. Let us take care of your needs, allowing you to focus on connecting with your community. Elevate your gatherings with scenic views and top-notch service, all at a budget-friendly price for larger groups.</p> */}
             </div>
           </div>
         </div>
         <div className="col-lg-6 col-md-6 order-lg-2 order-1">
           <Swiper
             autoplay={{
               delay: 3000,
               disableOnInteraction: false,
             }}
             pagination={{ clickable: true }}
             navigation={true}
             modules={[Autoplay, Navigation, Pagination]}
             className="projSwiper"
           >
            
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={social1} />
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={social2} />
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={social3} />
                 </div>
               </SwiperSlide>
           </Swiper>
         </div>
       </div>
       <div className="row mt-5 mb-5">
          <div className="col-lg-6 col-md-6">
           <Swiper
             autoplay={{
               delay: 3000,
               disableOnInteraction: false,
             }}
             pagination={{ clickable: true }}
             navigation={true}
             modules={[Autoplay, Navigation, Pagination]}
             className="projSwiper"
           >
            
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={corporate1} />
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={corporate2} />
                 </div>
               </SwiperSlide>
               <SwiperSlide>
                 <div className="Room-Img">
                   <img src={corporate3} />
                 </div>
               </SwiperSlide>
           
           </Swiper>
         </div>
         <div className="col-lg-6 col-md-6">
           <div className="Room-Desc">
             <div className="Room-Txt ml-1">
               <h2>Corporate Events</h2>
               <p>Our state-of-the-art facilities and scenic surroundings provide the perfect backdrop for successful business gatherings. From conferences to team-building retreats, we offer versatile event spaces, modern amenities, and professional services tailored to your corporate needs. Let our team ensure a seamless experience, allowing you to focus on productivity and fostering connections. Host your next corporate event with us and experience the perfect blend of business and leisure in a picturesque setting.
</p>
               {/* <p>Our state-of-the-art facilities and scenic surroundings provide the perfect backdrop for successful business gatherings. From conferences to team-building retreats, we offer versatile event spaces, modern amenities, and professional services tailored to your corporate needs. Let our team ensure a seamless experience, allowing you to focus on productivity and fostering connections. Host your next corporate event with us and experience the perfect blend of business and leisure in a picturesque setting. </p> */}
             </div>
           </div>
         </div>
         
       </div>
        </div>
      </section>
      
      {/* ========= wedding Enquiry ====== */}
      <section className="wedding-enquiry">
        <div className="container">
          <div class="headTitle">
            <h2>Dreammland Resort</h2>
            <p>
              Offers An incredibly Picturesque Location For The Wedding Of Your Dreams
            </p>
          </div>
          <div className="form-btn">
            <button className="theme-btn btn-style-one">
              <span className="btn-title"><Link to="/top-resort-thane" style={{color:'#fff'}}>Get Enquiry</Link></span>
            </button>
            {/* <Enquiry show={modalShow} onHide={() => setModalShow(false)} /> */}
          </div>
        </div>
      </section>
      {/* ======== wedding Gallery ======= */}
      <section className="wedding-gallery">
        <div className="pattern-top" />
        <div className="container">
          <div class="headTitle galleryHead">
            <h2>Our Destination Wedding Gallery</h2>
            <p>
              The ultimate Wedding destination gateway for an exciting luxurious
              escape in the lap of nature
            </p>
          </div>
          <Gallery photos={ProjectImages} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={ProjectImages.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
        {/* <div className="pattern-bottom" /> */}
      </section>
      <Footer />
    </>
  );
};

export default Wedding;
