import React from 'react'
import Footer from "./Footer";
import {Link} from "react-router-dom";
const Errorpage = () => {
  return (
    <div>
         {/* ======= About Banner ======= */}
      <section className="about-section">
        <div className="about-bottom-pattern" />
        <div className="aboutBanner">
          <div className="container">
            <div className="aboutbannerTxt">
            {/* <h1 style={{color:"#fff", fontSize:'10px'}}>Pure Veg Resorts in Thane</h1> */}
              <h2>
                404 Not Found
              </h2>
              <p>
                
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="about-count">
        <div className="container">
          <div class="headTitle">
            <div class="headTitle">
              <h2>Back to Home </h2>
              <h3 className="text-center" ><span><Link to="/">Home</Link></span></h3>
              {/* <p>
                Dreammland Resort located near Kundan Dam, Sirol, blessed by
                nature and mountains all around. with 40+Acres of land, Plotted
                and constructed with top amenities like Swimming Pool with
                Jacuzzi, Indoor/Outdoor Games, Landscape Garden, Play Park, Club
                House, Restaurant, Row House, Bungalows, Cottages, Studio
                Apartment and much more.
              </p> */}
            </div>
          </div>
          
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Errorpage