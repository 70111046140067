import React, { useState } from "react";
import "../Css/Resort.css";
// import CountUp from "react-countup";
// import Activities from "../img/room/room1.jpg";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";
// import Enquiry from "../Components/Enquiry";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import $ from "jquery";
// import DatePicker from "react-datepicker";
import RoomServices from "../Components/RoomServices";
import "react-datepicker/dist/react-datepicker.css";

const Resort = () => {

  const search = useLocation();
  const Path = search.pathname;
  const [serviceData, setServiceData] = useState(RoomServices);

  const villaList = serviceData.find((e) => e.Slugs == Path);

  console.log(villaList.ServiceName);

 
  
  return (
    <>
    
      {/* ======= Resort Banner ======= */}
      <section className="resort-section">
        <div className="resort-bottom-pattern" />
        <div className="resortBanner">
          <div className="container">
            <div className="resortbannerTxt">
            <h1 style={{color:"#fff", fontSize:'10px'}}>{villaList.Tagline}</h1>

              <h2>
              {villaList.Header}
              </h2>
              <p>
              {villaList.ShortDesc}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* === Resort About Section ===== */}
  {/* ===== Room Details1 ======= */}
  <section className="room-services1">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6">
              <div className="Room-Desc">
                <div className="Room-Txt">
                  <h2>{villaList.Header}</h2>
                  <p>{villaList.ParaDesc}</p>
                  {/* <p>{villaList.ParaDesc2}</p> */}
                </div>
              </div>
              {/* <div className="link-box mt-3">
                <Link to={villaList.Slugs} className="theme-btn btn-style-one">
                  <span className="btn-title">Enquire Now</span>
                </Link>
              </div> */}
            </div>
            <div className="col-lg-6 col-md-6">
              <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Autoplay, Navigation, Pagination]}
                className="projSwiper"
              >
                {villaList.imgData.map((imgValue) => (
                  <SwiperSlide>
                    <div className="Room-Img">
                      <img src={imgValue.roomImg} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Resort;
